<template>
  <div class="wrap">
    <div class="banner-box">
      <img src="/images/activity/title.png" class="title"/>
    </div>
    <div class="bottom-box">
      <div class="bottom-header-box">
        <div class="hot">热门活动</div>
        <div class="input-box">
          <input placeholder="输入活动关键词搜索" class="input" v-model="inputText"/>
          <img src="/images/activity/search.png" class="search-img-btn" @click="keywordsSearch(inputText)">
        </div>
      </div>

      <ul class="content-list">
        <li
            v-for="item in list"
            :key="item"
            class="list-item"
        >
          <ActivityItem :data="item" @click="goPage(`activitydetail?id=${item.id}`)"/>
        </li>
      </ul>
      <NoData :visible="!list.length"/>
      <div class="page">
        <Page
            class="page-tools"
            :size="params.pageSize"
            :current="pageNum"
            :total="total"
            @pageChange="pageChange"
        />
      </div>


    </div>
    <div class="last-box">
      <span class="pre-title">
        往期活动
      </span>
      <ul>
        <li v-for="item in list2"
            class="pre-list-item"
            :key="item">
          <PreActityItem style="margin-bottom: 0.23rem" :data="item" @click="goPage(`activitydetail?id=${item.id}`)"/>
        </li>
      </ul>
            <div class="page">
              <PageWhite
                  class="page-tools"
                  :size="params2.pageSize"
                  :current="pageNum2"
                  :total="total2"
                  @pageChange="pageChange2"
              />
            </div>
    </div>

  </div>
</template>

<script>
import ActivityItem from '@/components/activity/activity-item'
import Page from '@/components/common/pagination'
import PageWhite from '@/components/common/paginationwhite'
import NoData from '@/components/common/nodata/index.vue'
import PreActityItem from '@/components/activity/pre-activity-item'
import {onBeforeRouteUpdate, useRoute} from 'vue-router'
import {useList, useParams} from '@/hooks/useList'
import api from '@/api'
import {reactive, ref} from 'vue'

export default {
  name: 'index',
  components: {
    ActivityItem,
    Page,
    NoData,
    PreActityItem,
    PageWhite
  },
  setup () {
    const inputText = ref('');
    const route = useRoute()
    const {params, search,} = useParams({
      pageNum: route.query?.pn ?? 1,
      name: route.query?.name ?? '',
      pageSize: 6,
    })


    const p2 = useParams({
      pageNum: route.query?.pn2 ?? 1,
      pageSize: 3,
    })

    const params2 = p2.params
    const search2 = p2.search


    const {pageNum, total, list, getList} = useList(params, api.activityList)

    const ul2 = useList(params2, api.pastActivityList)
    const pageNum2 = ul2.pageNum
    const total2 = ul2.total
    const list2 = ul2.list
    const getList2 = ul2.getList

    onBeforeRouteUpdate((to) => {
      console.log(to)
      search({
        pageNum: to.query?.pn,
        name: to.query?.name
      })
      search2({
        pageNum: to.query?.pn2,
      })

    })

    return {
      pageNum,
      total,
      params,
      list,
      getList,
      search,
      pageNum2,
      total2,
      list2,
      getList2,
      params2,
      search2,
      inputText
    }
  },
  methods: {
    goPage (url) {
      this.$router.push(url)
    },
    resetParams () {
      this.$router.push({
        query: {
          pn: 1
        }
      })
    },
    pageChange (page) {
      this.$router.push({
        query: {
          ...this.$route.query,
          pn: page,

        }
      })
    },
    pageChange2 (page) {
      this.$router.push({
        query: {
          ...this.$route.query,
          pn2: page
        }
      })
    },
    keywordsSearch(text){
      this.$router.push({
        query: {
          name: text,
          pn:1,
        }
      })
    }
  },

}
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7.36rem;
  width: 100%;
  background: url("/images/activity/banner.png") no-repeat;
  background-size: 100% 100%;

  .banner {
    width: 100%;
    height: 7.36rem;
  }

  .title {
    width: 6.4rem;
    height: 1.6rem;
    z-index: 999;
  }

}

.bottom-box {
  //height: 9.5rem;
  width: 12rem;


  .bottom-header-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.3rem;

    .hot {
      font-weight: 500;
      font-size: 0.3rem;
    }

    .input-box {
      background: #F4F4F4;
      border-radius: 0.5rem;
      overflow: hidden;
      display: flex;
      padding: 0.05rem 0.2rem;
      align-items: center;

      .input {
        border: 0;
        outline: none;
        font-size: 0.12rem;
        background: transparent;

        &::placeholder {
          color: #666666;
        }
      }

      .search-img-btn {
        cursor: pointer;
        width: 0.19rem;
        height: 0.19rem;

      }
    }
  }

  .content-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 0.2rem;

    .list-item {
      position: relative;
      margin-bottom: 0.4rem;
      transition: all 300ms;

      &:not(:nth-of-type(3n)) {
        margin-right: 0.09rem;
      }

      &:hover {
        transition: all 300ms;
        transform: scale(1.01);
        cursor: pointer;
      }
    }
  }

  .page {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;

  }
}

.last-box {
  height: 7.22rem;
  width: 100%;
  background: #F4F4F4;
  display: flex;
  align-items: center;
  flex-direction: column;

  .pre-title {
    width: 12rem;
    padding: 0.4rem 0 0.23rem 0;
  }

  .pre-list-item {

  }

}

</style>
