<template>
  <div class="box">
      <img :src="data.img" class="banner">
    <div class="right-box">
        <div class="right-top-box">
            <span class="act-tag" :style="{'background':statusInfo.topColor}">
              {{ statusInfo.name }}
            </span>

          <span class="act-btn">
            查看详情
          </span>

        </div>
      <span class="title">
        {{data.name}}
      </span>

      <span class="desc">
       {{data.description}}
      </span>

      <div class="line"/>
      <div class="right-bottom-box">
        <span class="status">
          活动已结束
        </span>
        <span class="num">
<!--          已有122个作品参与-->
        </span>
      </div>

    </div>
  </div>
</template>

<script>
import {reactive} from 'vue'

export default {
  name: 'pre-activity-item',
  props:{
    data:{
      type:Object,
      default:null
    }
  },
  setup(props){
    let type = props.data.type || ''
    var info = {
      name: '投票活动',
      topColor: '#FFA235',
      statusColor: '#D6D6D6'
    }
    switch (type) {
      case 'VOTE_ACTIVE':
        info = {
          name: '投票活动',
          topColor: '#FFA235',
          statusColor: '#D6D6D6'
        }
        break
      case 'VIEW_ACTIVE':
        info = {
          name: '展示活动',
          topColor: '#00CD9B',
          statusColor: '#D6D6D6'
        }
        break
      case 'SIGN_ACTIVE':
        info = {
          name: '报名活动',
          topColor: '#01398D',
          statusColor: '#D6D6D6'
        }
        break
      case 'DRAW_ACTIVE':
        info = {
          name: '抽奖活动',
          topColor: '#FF663F',
          statusColor: '#D6D6D6'
        }
        break
    }
    const statusInfo = reactive(info);
    return {
      statusInfo
    }
  }
}
</script>

<style lang="scss" scoped>
  .box {
    height: 1.45rem;
    display: flex;
    background: white;
    width: 12rem;
    cursor: pointer;

    .banner {
      width: 2.84rem;
      height:  1.45rem;

    }

    .right-box {
      flex-direction: column;
      margin: 0 0.14rem;
      display: flex;
      flex: 1;


      .right-top-box {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.13rem 0;

          .act-tag {
            background: #FFA235;
            color: white;
            font-size: 0.14rem;
            padding: 0.04rem 0.1rem;
            line-height: 0.18rem;
            border-radius: 0.02rem;

          }

        .act-btn {
          width: 0.86rem;
          height: 0.26rem;
          border-radius: 0.13rem;
          border: 0.02rem solid #01398D;
          color: #01398D;
          font-size: 0.14rem;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }


      }

      .title {
        font-size: 0.16rem;
      }
      .desc {
        font-size: 0.12rem;
        color: #999999;
      }
      .line {
        background: #F2F2F2;
        height: 0.01rem;
      }
      .right-bottom-box {
        justify-content: space-between;
        display: flex;
        margin-top: 0.09rem;

        .status {
          font-size: 0.14rem;
          color: #D6D6D6;
        }
        .num {
          font-size: 0.14rem;
          color: #AAAAAA;
        }

      }
    }
  }
</style>
